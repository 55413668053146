<template>
  <responsive-layout v-if="$vuetify.breakpoint.mobile">
    <v-sheet light class="transparent">
      <div
        class="main py-8"
        :class="location.visited ? 'has-background-image' : ''"
      >
        <v-container fluid>
          <v-row justify="center" align="center" class="mx-4 mb-8 mb-lg-12">
            <v-col class="d-flex align-center justify-center" cols="12" md="12">
              <video
                :src="location.video"
                class="destination-video"
                autoplay
                @contextmenu="$event.preventDefault()"
                @ended="onVideoEnd()"
                ref="backgroundVideo"
              ></video>
            </v-col>

            <v-col cols="12" class="d-flex flex-column flex-md-row">
              <v-col
                cols="12"
                md="2"
                class="pl-0 pr-6 d-flex justify-start align-start"
              >
                <AppBackButton
                  class="ml-2 mt-2"
                  :to="{ name: 'TourDePandora' }"
                ></AppBackButton>
              </v-col>
            </v-col>

            <v-col
              class="
                d-flex
                align-center
                justify-center
                pl-10
                pr-10
                flex-column flex-md-row
              "
              cols="12"
              md="4"
              v-if="show_buttons"
            >
              <v-col
                cols="12"
                md="6"
                class="pl-0 pr-6 align-center justify-center d-flex"
              >
                <v-btn
                  class="ma-2 white--text rounded-0 elevation-0"
                  color="black"
                >
                  <router-link
                    class="white--text d-flex align-center justify-center"
                    to="/tour-de-pandora"
                  >
                    <v-icon left dark color="pink">
                      fas fa-chevron-left
                    </v-icon>
                    <span>back to tour de pandora</span>
                  </router-link>
                </v-btn>
              </v-col>

              <v-col cols="12" md="8" class="pl-0 pr-6">
                <v-btn
                  color="pink"
                  class="
                    mr-4
                    black--text
                    font-weight-bold
                    elevation-0
                    rounded-0
                  "
                  type="submit"
                  depressed
                  @click="replayVideo()"
                >
                  <span class="watch-again-button-text"> Watch again</span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-sheet>
  </responsive-layout>

  <aspect-ratio-layout v-else>
    <v-sheet class="d-flex flex-column transparent" light>
      <div
        class="main py-8"
        :class="location.visited ? 'has-background-image' : ''"
      >
        <v-container fluid>
          <v-row justify="center" align="center" class="mx-4 mb-8 mb-lg-12">
            <v-col class="d-flex align-center justify-center" cols="12" md="12">
              <video
                :src="location.video"
                class="destination-video"
                autoplay
                @contextmenu="$event.preventDefault()"
                @ended="onVideoEnd()"
                ref="backgroundVideo"
              ></video>
            </v-col>

            <v-col cols="12" class="d-flex flex-column flex-md-row">
              <v-col
                cols="12"
                md="2"
                class="pl-0 pr-6 d-flex justify-start align-start"
              >
                <v-btn
                  class="ma-2 white--text lobby-back-button rounded-0"
                  color="black"
                >
                  <router-link class="white--text" to="/tour-de-pandora">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">Back</span>
                  </router-link>
                </v-btn>
              </v-col>
            </v-col>

            <v-col
              class="
                d-flex
                align-center
                justify-center
                pl-10
                pr-10
                flex-column flex-md-row
              "
              cols="12"
              md="4"
              v-if="show_buttons"
            >
              <v-col
                cols="12"
                md="6"
                class="pl-0 pr-6 align-center justify-center d-flex"
              >
                <v-btn
                  class="ma-2 white--text rounded-0 elevation-0"
                  color="black"
                >
                  <router-link
                    class="white--text d-flex align-center justify-center"
                    to="/tour-de-pandora"
                  >
                    <v-icon left dark color="pink">
                      fas fa-chevron-left
                    </v-icon>
                    <span>back to tour de pandora</span>
                  </router-link>
                </v-btn>
              </v-col>

              <v-col cols="12" md="8" class="pl-0 pr-6">
                <v-btn
                  color="pink"
                  class="
                    mr-4
                    black--text
                    font-weight-bold
                    elevation-0
                    rounded-0
                  "
                  type="submit"
                  depressed
                  @click="replayVideo()"
                >
                  <span class="watch-again-button-text"> Watch again</span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-sheet>
  </aspect-ratio-layout>
</template>

<script>
import axios from "axios";

// Data
import STORE_NAMES from "@/assets/data/storenames";
import TERRITORY_MANAGERS from "@/assets/data/territorymanagers";
import theatreMockData from "../assets/data/boarding-videos.json";

// Components
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { mapState } from "vuex";
export default {
  name: "app-register",

  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      location: {},
      show_buttons: false,
    };
  },

  computed: {
    ...mapState(["event"]),
    isValid() {
      return Object.values(this.form).every((field) => !!field);
    },
  },

  async mounted() {
    await this.getLocation();
  },

  methods: {
    async getLocation() {
      const locationId = this.$route.params.id;
      const url = `${process.env.VUE_APP_API_URL}locations/${locationId}`;

      // Efter at kunden har set videoen
      // POST - url (locations/id)

      const { data } = await axios.get(url);

      this.location = data;
    },

    async onVideoEnd() {
      const locationId = this.$route.params.id;
      const url = `${process.env.VUE_APP_API_URL}locations/${locationId}`;

      // Efter at kunden har set videoen
      // POST - url (locations/id)

      const { data } = await axios.post(url);

      this.show_buttons = true;

      // Track the user

      if (this.event.state !== "ONDEMAND") {
        await axios.get(data.tracking_url);
      }

      this.location = data;
    },
    replayVideo() {
      console.log("Replaying background video");
      this.location.visited = false;
      this.show_buttons = false;

      this.$refs.backgroundVideo.play();
    },
  },
};
</script>

<style scoped>
/*
Needs to add padding to inner field
.v-input:not(.v-input--checkbox) {
  border: none !important;
  padding: 10px !important;
} */

.destination-video {
  object-fit: cover !important;

  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.has-background-image {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/PANDORA-Crafting-facilities.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
